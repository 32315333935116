<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="text-center w-100">
          <div class="title">Undangan {{ title }}</div>
          <span>from</span>
          <div class="mb-5 name">
            {{ wedding.man.surname }} & {{ wedding.woman.surname }}
          </div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      :background="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="bg-white agw-content"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <div class="d-none d-block" style="margin-top: -20px"></div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        style="position: absolute; transform: translateY(-100%);"
        class="d-md-none"
      >
        <path
          fill="#f1f0f1"
          fill-opacity=".9"
          d="M0,64L40,53.3C80,43,160,21,240,37.3C320,53,400,107,480,144C560,181,640,203,720,218.7C800,235,880,245,960,245.3C1040,245,1120,235,1200,192C1280,149,1360,75,1400,37.3L1440,0L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        style="position: absolute; transform: translateY(-100%);"
        class="d-md-none"
      >
        <path
          fill="#f1f0f1"
          fill-opacity=".9"
          d="M0,128L40,149.3C80,171,160,213,240,202.7C320,192,400,128,480,101.3C560,75,640,85,720,122.7C800,160,880,224,960,224C1040,224,1120,160,1200,117.3C1280,75,1360,53,1400,42.7L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>

      <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" />
      <AGWIntro id="intro" />
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />
      <AGWMap
        :height="450"
        :url="map"
        :directionUrl="direction"
        :location="location"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <!-- <Himbauan /> -->
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <FooterCeremonyku ref="footer" />

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/aryraditya/hendra-devi/GUN_5264%20++.jpeg?updatedAt=1712586166088";
const ImageHero2 =
  "https://ik.imagekit.io/aryraditya/hendra-devi/GUN_4778%20+.jpeg?updatedAt=1712586157962";
const ImageHero3 =
  "https://ik.imagekit.io/aryraditya/hendra-devi/GUN_5076%20++.jpeg?updatedAt=1712586163172";

import Audio from "@/assets/audio/backsound.mp3";

import AGWHero from "@/components/Hero/HeroOne";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";
// import Himbauan from "./components/Himbauan.vue";
import FooterCeremonyku from "@/components/Footer/Ceremonyku";

import { setInterval } from "timers";

const SESSION = [["2024-04-19 15:00", "Selesai"]];

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    // Himbauan,
    FooterCeremonyku
  },
  data() {
    return {
      title: "Pawiwahan",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location:
        "Jln. Dauh Rurung, Br. Kulibul Kawan, Desa Tibubeneng, Kuta Utara, Badung",
      map:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.584129409793!2d115.15794009999998!3d-8.635861199999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd2389372fd9c49%3A0x41951f11b437f05!2sJl.%20Dauh%20Rurung%20No.5%2C%20Tibubeneng%2C%20Kec.%20Kuta%20Utara%2C%20Kabupaten%20Badung%2C%20Bali%2080361!5e0!3m2!1sen!2sid!4v1712586696248!5m2!1sen!2sid",
      direction: "https://maps.app.goo.gl/hxrMjRZ3kAqyBnkL7",
      time: {
        start: this.$moment("2024-04-19 15:00"),
        end: "Selesai"
      },
      wedding: {
        man: {
          name: "I Kadek Adi Mahendra",
          surname: "Hendra",
          parents: " I Made Suartono & Ni Nyoman Serini",
          description: "Putra kedua",
          address:
            "Jln. Dauh Rurung, Br. Kulibul Kawan, Desa Tibubeneng, Kuta Utara, Badung",
          photo:
            "https://ik.imagekit.io/aryraditya/hendra-devi/GUN_5199%20++.jpeg?updatedAt=1712587561153&tr=w-400,h-400,fo-custom,cm-extract"
        },
        woman: {
          name: "Ni Putu Devi Purnamiasih ",
          surname: "Devi",
          parents: "I Putu Sukada Putra & Ni Made Suarmiasih",
          address: "Br Tiying Tutul, Pererenan, Gang Kamboja ",
          description: "Putri pertama",
          photo:
            "https://ik.imagekit.io/aryraditya/hendra-devi/GUN_5237%20++.jpeg?updatedAt=1712587550574&tr=w-342,h-342,fo-custom,cm-extract"
        }
      },
      mepandes: [
        // {
        //   name: 'I Gusti Ngurah Agung Teguh Aksama Putra',
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // },
      ],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    }
  },
  mounted() {
    const url = new URLSearchParams(window.location.search);
    const [start, end] =
      SESSION[(Number(url.get("sesi")) || 1) - 1] || SESSION[0];

    this.time.start = this.$moment(start);
    this.time.end = end;

    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>

<style lang="scss" scoped>
.overflow-fade-enter-active {
  transition: all 1s ease;
}
.overflow-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.overflow-fade-enter,
.overflow-fade-leave-to {
  opacity: 0;
}
</style>
